import React, { useCallback, useRef, useState } from "react";

import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

const libraries = ["places"];

const mapContainerStyles = {
  width: "100%",
  height: "100px",
};

const apiKey = "AIzaSyAIlg9Ke0MneV2byVvUb3hCN-l8U6kxbAc";

const GoogleMapsApi = ({ coordinates }) => {
  const center = coordinates
    ? JSON.parse(coordinates)
    : {
        lat: 19.0407246,
        lng: -98.2058503,
      };

  const [markers, setMarkers] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });

  const mapRef = useRef();
  const handleMapLoad = useCallback((map) => {
    mapRef.current = map;

    const railsCoordinates = coordinates;

    if (railsCoordinates) {
      const { lat, lng } = JSON.parse(coordinates);
      setMarkers({ lat, lng });
      panTo({ lat, lng });
    }
  }, []);

  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(15);
  }, []);

  if (loadError) return "error loading map";
  if (!isLoaded) return "Loading maps";

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyles}
      zoom={15}
      center={center}
      onLoad={handleMapLoad}
    >
      {markers && (
        <Marker
          position={{ lat: markers.lat, lng: markers.lng }}
        />
      )}
    </GoogleMap>
  );
};

export default GoogleMapsApi;
