import React, { useLayoutEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/swiper-bundle.css";
import { useWindowSize } from '../hooks/useWindowSize';

const ArrowIcon = (
  <>
    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.666016 4.83404V6.16737H8.66602L4.99935 9.83404L5.94602 10.7807L11.226 5.5007L5.94602 0.220703L4.99935 1.16737L8.66602 4.83404H0.666016Z" fill="#E31519"/>
    </svg>
  </>
);

const SwipeControl = ({ back, refer }) => {
  const swiper = useSwiper();
  return (
    <button
      ref={refer}
      onClick={() => (back ? swiper.slidePrev() : swiper.slideNext())}
      className="d-none"
    />
  );
};

const Playlist = ({ title, videos, url }) => {
  const [navigationActive, setNavigationActive] = useState(false);
  const [swiped, setSwiped] = useState(false);
  const size = useWindowSize();
  const slideBack = useRef();
  const slideForward = useRef();

  useLayoutEffect(() => {
    if (size[0] >= 800) {
      setNavigationActive(true);
    }
  }, [size]);

  const handleSwipeAhead = () => {
    slideForward.current.click();
    setSwiped(true);
  };

  return (
    <>
      <div className="playlist__header">
        <h2 className="playlist__header__title" dangerouslySetInnerHTML={{ __html: title }} />
        <hr />
        {url && (
          <a href={url} className="playlist__header__more">
            Ver todos
            { ArrowIcon }
          </a>
        )}
      </div>
      <div className="playlist-container">
        {swiped && (
          <div
            className="playlist-container__arrow --arrow-back"
            onClick={() => slideBack.current.click()}
          >
            <svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.316406 18.1333L7.94974 10.5L0.316406 2.85L2.66641 0.5L12.6664 10.5L2.66641 20.5L0.316406 18.1333Z" fill="#C6C6C6"/>
            </svg>
          </div>
        )}
        <Swiper
          slidesPerView={"auto"}
          className="playlist-swiper"
          loop={ false }
          >
          <SwipeControl refer={slideBack} back />
          <SwipeControl refer={slideForward} />
          {videos.map(({ id, title, url, date, thumbnail }) => (
            <SwiperSlide key={ id }>
              <a href={ url } className="card">
                <div className="card__image">
                  <img src={ thumbnail } alt={ title } />
                </div>
                <h3 className="card__title">{ title }</h3>
                <p className="card__date">{ date }</p>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
        {videos.length > 4 && (
          <div
            className="playlist-container__arrow"
            onClick={handleSwipeAhead}
          >
            <svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.316406 18.1333L7.94974 10.5L0.316406 2.85L2.66641 0.5L12.6664 10.5L2.66641 20.5L0.316406 18.1333Z" fill="#C6C6C6"/>
            </svg>
          </div>
        )}
      </div>
    </>
  )
}

Playlist.propTypes = {}

export default Playlist
