import React, { useRef } from "react";
import { useEventFilters } from "../../hooks/useEventFilters";

const gobackIcon = (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.6425 3.875L4.2275 5.46L3.34375 6.34375L0.25 3.25L3.34375 0.15625L4.2275 1.04L2.6425 2.625H7.125C8.45108 2.625 9.72285 3.15178 10.6605 4.08947C11.5982 5.02715 12.125 6.29892 12.125 7.625C12.125 8.95108 11.5982 10.2229 10.6605 11.1605C9.72285 12.0982 8.45108 12.625 7.125 12.625H1.5V11.375H7.125C8.11956 11.375 9.07339 10.9799 9.77665 10.2767C10.4799 9.57339 10.875 8.61956 10.875 7.625C10.875 6.63044 10.4799 5.67661 9.77665 4.97335C9.07339 4.27009 8.11956 3.875 7.125 3.875H2.6425Z"
      fill="#021D49"
    />
  </svg>
);

const EventCategorySidebar = ({ eventCategory, categories }) => {
  const { urlSearch, selectOption, eventPrices, cleanOption, eventDates } =
    useEventFilters();

  const minPrice = useRef(null);
  const maxPrice = useRef(null);

  const handleSelectPrice = (target) => {
    if (target.value != urlSearch.price) {
      selectOption(target);
      cleanOption("price_between");
    } else {
      cleanOption("price");
    }
  };

  const handleSelectDay = ({ target }) => {
    if (target.value != urlSearch.date) {
      selectOption(target);
    } else {
      cleanOption("date");
    }
  };

  const handlePriceBetween = () => {
    const minValue = !parseInt(minPrice.current.value)
      ? "empty"
      : minPrice.current.value;
    const maxValue = !parseInt(maxPrice.current.value)
      ? "empty"
      : maxPrice.current.value;
    const value = `${minValue}-${maxValue}`;

    if (value !== "empty-empty") {
      selectOption({ name: "price_between", value: value });
      cleanOption("price");
    }
  };

  return (
    <div className="event-sidebar">
      <div className="event-sidebar__header">
        <h3 className="event-sidebar__header__title">Categorías</h3>
      </div>
      <ul className="event-sidebar__categories">
        {categories.map((category) => (
          <li className="event-sidebar__categories__category">
            <a
              href={category.url}
              key={category.id}
              className={
                eventCategory && eventCategory.id == category.id
                  ? "--active"
                  : ""
              }
            >
              {/* <img src={category.icon} alt={category.name} /> */}
              {category.name}
            </a>
          </li>
        ))}
      </ul>
      <div className="event-sidebar__date">
        <h3 className="event-sidebar__date__title">Fechas</h3>
        {eventDates.map((eventDate) => (
          <div className="event-sidebar__date__filter" key={eventDate.name}>
            <input
              type="checkbox"
              id={`date_${eventDate.name}`}
              onChange={handleSelectDay}
              value={eventDate.value}
              name="date"
              checked={eventDate.value === urlSearch.date ? "true" : ""}
            />
            <label htmlFor={`date_${eventDate.name}`}>{eventDate.name}</label>
          </div>
        ))}
      </div>
      <div className="event-sidebar__price">
        <h3 className="event-sidebar__price__title">Costos</h3>
        <ul className="event-sidebar__price__filters">
          {eventPrices.map((eventPrice) => (
            <li
              key={eventPrice.name}
              className={`event-sidebar__price__filters__filter ${
                eventPrice.value === urlSearch.price ? "--active" : ""
              }`}
              onClick={() => {
                handleSelectPrice({ name: "price", value: eventPrice.value });
              }}
            >
              {eventPrice.name}
            </li>
          ))}
        </ul>
      </div>
      <div className="event-sidebar__price-range">
        <h3 className="event-sidebar__price-range__title">Rango de precio</h3>
        <div className="event-sidebar__price-range__filters">
          <input type="text" placeholder="Min" ref={minPrice} />
          <input type="text" placeholder="Max" ref={maxPrice} />
        </div>
        <button
          className="event-sidebar__price-range__button"
          onClick={handlePriceBetween}
        >
          Aplicar
        </button>
      </div>
    </div>
  );
};

export default EventCategorySidebar;
