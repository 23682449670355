import React, { useRef } from "react";
import PropTypes from "prop-types";

const arrowRight = (
  <svg
    width="5"
    height="8"
    viewBox="0 0 5 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.369141 7.12227L3.23164 4.25977L0.369141 1.39102L1.25039 0.509766L5.00039 4.25977L1.25039 8.00977L0.369141 7.12227Z"
      fill="#DB3D91"
    />
  </svg>
);

const SubcategoryList = ({ subcategories }) => {
  const list = useRef();

  const handleScroll = () => {
    const { scrollLeft, scrollWidth, offsetWidth } = list.current;

    list.current.scrollTo({
      top: 0,
      left: offsetWidth === scrollWidth - scrollLeft ? 0 : scrollLeft + 300,
      behavior: "smooth",
    });
  };

  return (
    <>
      <ul className="subcategory-list" ref={list}>
        {subcategories.map((sbctgy) => (
          <li key={sbctgy.name}>
            <a href={sbctgy.url}>
              {/* {sbctgy.iconUrl && <img src={sbctgy.iconUrl} alt="" />} */}
              {sbctgy.name}
            </a>
          </li>
        ))}
      </ul>

      <button type="button" onClick={handleScroll}>
        {arrowRight}
      </button>
    </>
  );
};

SubcategoryList.propTypes = {
  current: PropTypes.shape({
    name: PropTypes.string.isRequired,
    iconUrl: PropTypes.string.isRequired,
  }).isRequired,
  subcategories: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SubcategoryList;
