import React from "react";

const dateIcon = (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8333 15.5932H2.16667V6.42655H13.8333M11.3333 0.593216V2.25988H4.66667V0.593216H3V2.25988H2.16667C1.24167 2.25988 0.5 3.00155 0.5 3.92655V15.5932C0.5 16.0352 0.675595 16.4592 0.988155 16.7717C1.30072 17.0843 1.72464 17.2599 2.16667 17.2599H13.8333C14.2754 17.2599 14.6993 17.0843 15.0118 16.7717C15.3244 16.4592 15.5 16.0352 15.5 15.5932V3.92655C15.5 3.00155 14.75 2.25988 13.8333 2.25988H13V0.593216"
      fill="#ADB5BD"
    />
  </svg>
);

const clockIcon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00008 0.426559C4.41675 0.426559 0.666748 4.17656 0.666748 8.75989C0.666748 13.3432 4.41675 17.0932 9.00008 17.0932C13.5834 17.0932 17.3334 13.3432 17.3334 8.75989C17.3334 4.17656 13.5834 0.426559 9.00008 0.426559ZM10.6667 12.9266L8.16675 8.59323V4.59323H9.41675V8.25989L11.7501 12.3432L10.6667 12.9266Z"
      fill="#ADB5BD"
    />
  </svg>
);

const placeIcon = (
  <svg
    width="12"
    height="18"
    viewBox="0 0 12 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00008 8.34323C5.44755 8.34323 4.91764 8.12373 4.52694 7.73303C4.13624 7.34233 3.91675 6.81243 3.91675 6.25989C3.91675 5.70736 4.13624 5.17745 4.52694 4.78675C4.91764 4.39605 5.44755 4.17656 6.00008 4.17656C6.55262 4.17656 7.08252 4.39605 7.47322 4.78675C7.86392 5.17745 8.08342 5.70736 8.08342 6.25989C8.08342 6.53348 8.02953 6.80439 7.92483 7.05715C7.82013 7.30991 7.66668 7.53958 7.47322 7.73303C7.27977 7.92649 7.0501 8.07994 6.79734 8.18464C6.54458 8.28934 6.27367 8.34323 6.00008 8.34323ZM6.00008 0.426559C4.45299 0.426559 2.96925 1.04114 1.87529 2.1351C0.78133 3.22907 0.166748 4.7128 0.166748 6.25989C0.166748 10.6349 6.00008 17.0932 6.00008 17.0932C6.00008 17.0932 11.8334 10.6349 11.8334 6.25989C11.8334 4.7128 11.2188 3.22907 10.1249 2.1351C9.03091 1.04114 7.54718 0.426559 6.00008 0.426559Z"
      fill="#ADB5BD"
    />
  </svg>
);

const EventCard = ({
  title,
  time,
  place,
  date,
  coverPageUrl,
  path,
  category,
}) => {
  return (
    <a href={path} className="event-card">
      <div
        className="event-card__image"
        style={{ backgroundImage: `url(${coverPageUrl})` }}
      >
        <span>{category.name}</span>
        {category.icon && <img src={category.icon} />}
      </div>
      <div className="event-card__information">
        <h3 className="event-card__information__title">{title}</h3>

        {date && (
          <div className="event-card__information__item">
            {dateIcon}
            {date}
          </div>
        )}
        {time && (
          <div className="event-card__information__item">
            {clockIcon}
            {time}
          </div>
        )}
        {place && (
          <div className="event-card__information__item">
            {placeIcon}
            {place}
          </div>
        )}
      </div>
    </a>
  );
};

export default EventCard;
