import React, { useEffect, useState } from "react";
import SearchResults from "./SearchResults";

const lens = (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.5 0.759766C8.22391 0.759766 9.87721 1.44458 11.0962 2.66357C12.3152 3.88256 13 5.53586 13 7.25977C13 8.86977 12.41 10.3498 11.44 11.4898L11.71 11.7598H12.5L17.5 16.7598L16 18.2598L11 13.2598V12.4698L10.73 12.1998C9.59 13.1698 8.11 13.7598 6.5 13.7598C4.77609 13.7598 3.12279 13.0749 1.90381 11.856C0.684819 10.637 0 8.98367 0 7.25977C0 5.53586 0.684819 3.88256 1.90381 2.66357C3.12279 1.44458 4.77609 0.759766 6.5 0.759766ZM6.5 2.75977C4 2.75977 2 4.75977 2 7.25977C2 9.75977 4 11.7598 6.5 11.7598C9 11.7598 11 9.75977 11 7.25977C11 4.75977 9 2.75977 6.5 2.75977Z" fill="#ADB5BD"/>
  </svg>
);

const Hero = ({ icons }) => {
  const [focus, setFocus] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const results = async () => {
      if (searchQuery.trim() != "") {
        const suggestionsResult = await getSearchResults(searchQuery);
        setSuggestions(suggestionsResult);
      } else {
        setSuggestions([]);
      }
    };

    results();
  }, [searchQuery]);

  const handleFocus = () => {
    setFocus(false);
    setSearchQuery("");
  };

  const turnFocus = () => {
    setFocus(true);
    window.scrollY = 0;
  };

  const getSearchResults = async (name) => {
    const response = await fetch(`/search/main?search_name=${name}`);
    const results = await response.json();
    return results;
  };

  return (
    <div className={`hero ${focus ? "hero--focus" : ""}`}>
      <div className="hero__search">
        <button onClick={handleFocus} className="hero__search__back" />

        <div className="hero__search__wrap">
          {lens}
          <input
            type="search"
            onClick={turnFocus}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="hero__search__input"
            id="hero-search"
            placeholder="Buscar por entidades, tipos de eventos, lugares y más..."
            value={searchQuery}
          />
          <button type="submit">Buscar</button>
          
          <SearchResults results={suggestions} icons={icons} />
        </div>
      </div>
    </div>
  );
};

export default Hero;
