import React, { useState, useRef } from "react";
import EventCard from "./EventCard";

const arrow = (
  <svg
    width="7"
    height="10"
    viewBox="0 0 7 10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.324707 8.81667L4.14137 5L0.324707 1.175L1.49971 0L6.49971 5L1.49971 10L0.324707 8.81667Z" />
  </svg>
);

const Event = ({ header, events }) => {
  const cards = useRef();

  const handlePagination = (direction) => {
    const { scrollLeft } = cards.current;

    cards.current.scrollTo({
      top: 0,
      left: scrollLeft + direction,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="event__header">
        <h2 className="event__header__title" dangerouslySetInnerHTML={{__html: header.title}} />
        <hr />
        <div className="event__options__pagination">
          <button
            type="button"
            className="event__options__pagination__before"
            onClick={() => handlePagination(-300)}
          >
            {arrow}
          </button>
          <button
            type="button"
            className="event__options__pagination__next"
            onClick={() => handlePagination(280)}
          >
            {arrow}
          </button>
        </div>
      </div>
      <div className="event__cards" ref={cards}>
        {events.map((event) => (
          <EventCard {...event} key={event.title} />
        ))}
      </div>
    </>
  );
};

export default Event;
